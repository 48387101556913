import React from "react"
import styled from "@emotion/styled"

import Seo from "../components/seo"
import Layout from "../components/layout"

import ImageLoader from "../components/image-loader"
import { borders, shadows } from "../styles"

const PerformancesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

const PerformancesImage = styled.div`
  border: ${borders.standard};
  border-radius: 0;
  box-shadow: ${shadows.standard};
  overflow: hidden;

  @media screen and (min-width: 768px) {
    flex: 1;
    border-radius: 0.5rem;
  }
`

const PerformancesBlurb = styled.div`
  padding: 1rem 2rem;
`

const PerformancesPage = () => {
  return (
    <Layout>
      <Seo
        title="Performances | Sing Smart Studios | Townsville Singing School"
        keywords={[
          `demonstrations`,
          `performing`,
          `grow`,
          `mic-nights`,
          `stage`,
        ]}
      />
      <PerformancesContainer>
        <PerformancesBlurb>
          <h2>Performance opportunities</h2>
          <p>
            Sing Smart Studios holds 'opt-in' term concerts for students to
            showcase their hard work to family and friends and gain valuable
            experience performing.
          </p>
          <p>
            There are also opportunities to participate in community events that
            are held around the city.
          </p>
        </PerformancesBlurb>

        <PerformancesImage>
          <ImageLoader alt="Performances" src="/assets/performances.jpg" />
        </PerformancesImage>
      </PerformancesContainer>
    </Layout>
  )
}

export default PerformancesPage
